import React, { useState } from "react"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import { comparePasswords } from "./utils/utils"
import usePasswordResetRequest from "../../utils/hooks/usePasswordReset"
import TextInput from "../base/forms/textInput"
import queryString from "query-string"
import Modal from "../layout/modal"
import Button from "../base/buttons/regular"
import notifications from "../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"
import { navigateTo } from "gatsby"

const messages = defineMessages({
  title: "Reset Password",
  passwordInputPlaceholder: "Password",
  passwordInputPasswordsNotMatchError: "Passwords must match",
  passwordResetSuccessNotificationTitle: "Password reset successful!",
  passwordResetSuccessNotificationMessage: "You should receive an email shortly",
  passwordResetErrorNotificationTitle: "Oops! Something went wrong.",
  passwordResetSubmitButton: "Reset Password",
})

const ResetPassword = () => {
  const token = queryString.parse(window.location.search).token

  const [navigationVisible, setNavigationVisible] = useState(true)
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showErrors, setShowErrors] = useState(false)

  const formatMessage = useFormatMessage()
  const currentSize = useWindowSize()
  const { loading: submitLoading, resetPassword } = usePasswordResetRequest()

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && navigationVisible) {
    setNavigationVisible(false)
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && !navigationVisible) {
    setNavigationVisible(true)
    setWidthOfModal("w-3/5")
  }
  const passwordsTheSame = comparePasswords(password, confirmPassword)

  const handle = async () => {
    if (!passwordsTheSame) {
      setShowErrors(true)
      return
    }
    try {
      await resetPassword(token, password)
      notifications.success(
        formatMessage(messages.passwordResetSuccessNotificationTitle),
        formatMessage(messages.passwordResetSuccessNotificationMessage)
      )
      navigateTo("/admin/login")
    } catch (error) {
      notifications.error(formatMessage(messages.passwordResetErrorNotificationTitle), error.message, error.details)
    }
  }
  return (
    <Modal noTitle mdWidth={widthOfModal} navigation={false} mobile={!navigationVisible} lgWidth="w-3/5" next="/admin">
      <div className="text-gray-700 text-2xl text-center font-semibold">{formatMessage(messages.title)}</div>
      {/*TODO REMOVE AND REDIRECT WHEN NO TOKEN*/}
      {!token && <div className="mt-5 w-full items-center flex flex-col">Please request a password reset link first!</div>}{" "}
      {token && (
        <>
          <div className="mt-5 w-full items-center flex flex-col">
            <TextInput
              type="password"
              placeholder={formatMessage(messages.passwordInputPlaceholder)}
              className="mt-3 my-3 py-2 w-2/3"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <TextInput
              type="password"
              placeholder={formatMessage(messages.passwordInputPlaceholder)}
              className="mt-3 my-3 py-2 w-2/3"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              errors={["Passwords must match"]}
              invalid={showErrors && !passwordsTheSame}
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full">
            <div className="w-1/2">
              <Button primary className="text-center w-full" onClick={handle} loading={submitLoading}>
                {formatMessage(messages.passwordResetSubmitButton)}
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

export default ResetPassword
